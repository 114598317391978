import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Help from "../Home/Help";
import Card5 from "../Common/Card/Card5";

const LeisureGames = (props) => {
  return (
    <Fragment>
      <div className="leisure_games mt-md-4">
        <div className="main">
          <Container>
            <Card5
              title="FUNCITY"
              para="Fun City forms an integral part of the society by providing a platform to children from 1-12 years old for social interactions and active play in a safe, secure and conveniently accessed location. Fun City has a simple invitation to one and all; Come, play!"
              link="https://www.phoenixmallofasia.com/brand/Funcity"
              button="KNOW MORE"
              image="img/Leisure/game1.jpg"
            />
            <div className="reverse">
              <Card5
                title="TIMEZONE"
                para="TIME FOR FUN is a catchphrase that deftly captures the heart and spirit of Timezone. This is a brand that represents a fun, entertainment venue where customers venture into another dimension, or 'Timezone'."
                link="https://www.phoenixmallofasia.com/brand/Timezone"
                button="KNOW MORE"
                image="img/Leisure/game2.jpg"
              />
            </div>
            <Card5
              title="HAMLEYS"
              para="Hamleys is the oldest toy shop in the world. 255 years ago, Cornishman William Hamley opened his toy store, Noah's Ark, in Holborn, London. Over the years, the toy shop was renamed Hamleys, in honour of its founder.The names of the Hamleys mascot bears are 'Hamley' and 'Hattie'."
              link="https://www.phoenixmallofasia.com/brand/Hamleys"
              button="KNOW MORE"
              image="img/Leisure/game3.jpg"
            />
          </Container>
        </div>
        {/* <Nhance /> */}
        <Help/>
      </div>
    </Fragment>
  );
};

export default LeisureGames;
